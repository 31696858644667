import { useEffect } from 'react';
import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';

import RadioGroupButton from '../../../components/form/RadioGroupButton';
import {
  EBOOK_TYPE_OPTIONS,
  PRICE_SETTING_OPTIONS,
  RATING_OPTIONS,
  EXPLORE_TYPE_OPTIONS,
  SORT_BY_EXPLORE_BEST_SELLER_OPTIONS,
  SORT_BY_EXPLORE_OPTIONS,
  EBOOK_FILE_TYPE_OPTIONS,
  EXPLORE_TYPE_VALUE,
  CARTOON_TYPE_OPTIONS,
} from '../validates/sectionBuilderSchema';

import DropdownSelectNested from './DropdownSelectNested';
import SelectPintoCategoryWrapper from './SelectPintoCategory';

export type SearchType = 'ebook' | 'user';

export interface IExploreSectionTarget {
  //TODO: continue implements
  register: UseFormRegister<FieldValues>;
  onSelectCategoryId?: (category: number) => void;
}

const ExploreSectionTarget = (props: IExploreSectionTarget) => {
  const { register } = props;
  const { watch, setValue } = useFormContext();
  const [categoryId, exploreType, sortBy, sortType] = watch(['target.categoryId', 'target.exploreType', 'target.sortBy', 'target.sortType']);
  useEffect(() => {
    if (!exploreType) {
      setValue('target.exploreType', EXPLORE_TYPE_OPTIONS[0].value);
    }
  }, [exploreType, setValue]);
  useEffect(() => {
    // this useEffect for default target.sortBy and target.sortType in case of select Explore as target of Section

    if (!sortBy && !sortType) {
      // in case there are no sortBy and sortType this condition will triggered
      if (exploreType === EXPLORE_TYPE_VALUE.BestSeller) {
        // if exploreType is BestSeller it will default first item from SORT_BY_EXPLORE_BEST_SELLER_OPTIONS to target.sortBy and target.sortType
        SORT_BY_EXPLORE_BEST_SELLER_OPTIONS[0].value.forEach((opt) => {
          setValue(`target.${opt.keyName}`, opt.value);
        });
      } else if (exploreType === EXPLORE_TYPE_VALUE.All) {
        // if exploreType is All it will default first item from SORT_BY_EXPLORE_OPTIONS to target.sortBy and target.sortType
        SORT_BY_EXPLORE_OPTIONS[0].value.forEach((opt) => {
          setValue(`target.${opt.keyName}`, opt.value);
        });
      }
      // if exploreType is not All and BestSeller sortBy and sortType will not be default by any options
    } else if (sortBy && sortType) {
      // in case there are target.sortBy and target.sortType have values this condition will triggered
      if (![EXPLORE_TYPE_VALUE.All, EXPLORE_TYPE_VALUE.BestSeller].includes(exploreType)) {
        // when exploreType is not All and BestSeller will set sortBy and sortType to null
        setValue('target.sortBy', null);
        setValue('target.sortType', null);
      } else if (exploreType === EXPLORE_TYPE_VALUE.BestSeller && !SORT_BY_EXPLORE_BEST_SELLER_OPTIONS.find((v) => v.value[0].value === sortBy && v.value[1].value === sortType)) {
        // if exploreType is BesSeller and current sortBy and sortType is not choice in SORT_BY_EXPLORE_BEST_SELLER_OPTIONS this will set new default value
        SORT_BY_EXPLORE_BEST_SELLER_OPTIONS[0].value.forEach((opt) => {
          setValue(`target.${opt.keyName}`, opt.value);
        });
      } else if (exploreType === EXPLORE_TYPE_VALUE.All && !SORT_BY_EXPLORE_OPTIONS.find((v) => v.value[0].value === sortBy && v.value[1].value === sortType)) {
        // if exploreType is All and current sortBy and sortType is not choice in SORT_BY_EXPLORE_OPTIONS this will set new default value
        SORT_BY_EXPLORE_OPTIONS[0].value.forEach((opt) => {
          setValue(`target.${opt.keyName}`, opt.value);
        });
      }
    }
  }, [exploreType, setValue, sortBy, sortType]);
  return (
    <div className="space-y-8 py-8">
      <RadioGroupButton label="Explore Topic" options={EXPLORE_TYPE_OPTIONS} register={register} name={`target.exploreType`} />

      {[EXPLORE_TYPE_VALUE.All, EXPLORE_TYPE_VALUE.BestSeller].includes(exploreType) && (
        <DropdownSelectNested
          label="SortBy"
          options={exploreType === EXPLORE_TYPE_VALUE.BestSeller ? SORT_BY_EXPLORE_BEST_SELLER_OPTIONS : SORT_BY_EXPLORE_OPTIONS}
          prefixName={`target`}
          name={[`target.sortBy`, 'target.sortType']}
        />
      )}

      <SelectPintoCategoryWrapper name={`target.categoryId`} categoryId={categoryId} />

      <RadioGroupButton label="Rating" options={RATING_OPTIONS} register={register} name={`target.gteRating`} />

      <RadioGroupButton isSetArrayValue label="Price Type" options={PRICE_SETTING_OPTIONS} register={register} name={`target.anyPriceTypes`} />

      <RadioGroupButton isSetArrayValue label="Ebook Type" options={EBOOK_TYPE_OPTIONS} register={register} name={`target.anyTypes`} />

      <RadioGroupButton isSetArrayValue label="File Type" options={EBOOK_FILE_TYPE_OPTIONS} register={register} name={`target.anyFileTypes`} />

      {categoryId === '2' && <RadioGroupButton isSetArrayValue label="Cartoon Type" options={CARTOON_TYPE_OPTIONS} register={register} name={`target.anyWritingTypes`} />}
    </div>
  );
};

export default ExploreSectionTarget;
