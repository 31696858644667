import { debounce } from 'lodash';
import { ChangeEvent, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';

interface IPropsFilterAndSearchPresenter {
  onSetSearchText: (text?: string) => void;
}

const FilterAndSearchPresenter = ({ onSetSearchText }: IPropsFilterAndSearchPresenter) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeKeyWord = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      onSetSearchText(e.target.value);
    }, 500),
    [],
  );

  return (
    <div className="flex items-center justify-between">
      <div className="relative">
        <FiSearch className="absolute top-[16px] left-[12px] text-colorsBrandWarmBlack02LabelSecondary text-18" />
        <input type="text" placeholder="Search ID or Title" className="w-[300px] pl-36 input" onChange={onChangeKeyWord} />
      </div>
    </div>
  );
};

export default FilterAndSearchPresenter;
