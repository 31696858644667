import classNames from 'classnames';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectPintoCategory from '../../../components/modal/SelectPintoCategory';

interface IPropsSelectPintoCategory {
  name: string;
  categoryId?: number;
}

const SelectPintoCategoryWrapper = (props: IPropsSelectPintoCategory) => {
  const { setValue } = useFormContext();
  const { categoryId, name } = props;

  const onSetPintoCategory = useCallback(
    (categories) => {
      const selectedCategoryId = categories?.[(categories?.length || 0) - 1]._id;
      setValue?.(name, selectedCategoryId);

      if (selectedCategoryId !== '2') setValue('target.anyWritingTypes', []);
    },
    [name, setValue],
  );

  return (
    <div>
      <label className="label">
        <span className="label-text text-systemGrays02LabelSecondary">
          Category <span className="text-colorsBrandRed01Primary">*</span>
        </span>
      </label>
      <div className="space-x-16">
        <div
          className={classNames('py-16 badge', {
            'text-colorsBrandRed01Primary bg-transparent border-colorsBrandRed01Primary': !!categoryId,
            'badge-outline': !categoryId,
          })}
        >{`CategoryId: ${categoryId || '-'}`}</div>
        <SelectPintoCategory openModalLabel={categoryId ? 'change Category' : 'select category'} onConfirm={onSetPintoCategory} name={name} />
      </div>
    </div>
  );
};

export default SelectPintoCategoryWrapper;
